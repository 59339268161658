<template>
  <nav class="relative w-full z-10">
    <div class="brace max-lg:px-0 lg:py-6">
      <div class="bg-green text-white transition-height duration-300 shadow-[0_0_15px_0_rgba(0,0,0,0.15)] rounded-b-2xl lg:rounded-xl">
        <div class="flex items-center pl-2 xxs:px-4 pr-2 py-4 gap-2 lg:px-6 lg:py-4">
          <!-- Left Element -->
          <nuxt-link class="block mr-auto text-2xl sm:text-3xl font-bold font-degular min-w-[65px]" :to="localePath('quit')" :class="enLanguage ? '' : 'mb-1'">
            <img :src="logo" style="filter: invert(1);" class="w-[65px] xxs:w-[85px] md:w-24"/>
          </nuxt-link>

          <!-- Center Element -->
          <div class="absolute left-1/2 transform -translate-x-1/2 hidden lg:flex gap-10 justify-center whitespace-nowrap font-degular">
            <nuxt-link class="flex-none font-bold" :to="localePath('quit-subpage-index-pro')">{{ t('navbar.health_pro') }}</nuxt-link>
            <nuxt-link class="flex-none font-bold" :to="localePath('quit-subpage-index-hesitate-index')">{{ t('navbar.prepare') }}</nuxt-link>
          </div>

          <!-- Right Element Desktop -->
          <div class="hidden md:flex ml-auto gap-6 flex items-baseline">
            <nuxt-link class="flex items-center px-3 font-bold font-degular"
                      :to="locale == 'fr' ? '/jarrete/connexion' : '/iquitnow/login'"
                      external>{{ t('navbar.connect') }}</nuxt-link>
            <nuxt-link class="btn btn--outline btn--invert h-9"
                      :to="locale == 'fr' ? '/jarrete/inscription' : '/iquitnow/registration'"
                      external>{{ t('navbar.register') }}</nuxt-link>
          </div>


          <!-- Right Element Mobile -->
          <div class="flex md:hidden gap-1 xxs:gap-2 ml-3 xxs:ml-4">
            <nuxt-link class="leading-5 text-[0.875rem]	xxs:leading-6 xxs:text-base flex items-center px-1 font-bold font-degular"
                      :to="locale == 'fr' ? '/jarrete/connexion' : '/iquitnow/login'"
                      external>{{ t('navbar.connect_mobile') }}</nuxt-link>
            <nuxt-link class="xxs:py-[0.3125rem] xxs:px-[1.5rem] py-[0.25rem] px-[1rem] leading-5 text-[0.875rem] xxs:leading-6 xxs:text-base btn btn--outline btn--invert h-9 flex items-center xxs:block"
                      :to="locale == 'fr' ? '/jarrete/inscription' : '/iquitnow/registration'"
                      external>{{ t('navbar.register_mobile') }}</nuxt-link>
          </div>

          <!-- Hamburger Button -->
          <div class="lg:hidden">
            <button class="btn flex-none w-9 h-9 p-0 hover:bg-transparent"
                    :class="{ open: headerOpen }"
                    type="button"
                    @click="headerOpen = !headerOpen">
              <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1.00006" y="35.5" width="33.9999" height="33.9999" rx="17" transform="rotate(-90 1.00006 35.5)" fill="white"/>
                <rect x="1.00006" y="35.5" width="33.9999" height="33.9999" rx="17" transform="rotate(-90 1.00006 35.5)" stroke="white" stroke-width="2"/>
                <rect x="9.14615" y="22.3049" width="17.7078" height="1.6098" rx="0.804899" fill="#09B050"/>
                <rect x="9.14615" y="17.6951" width="17.7078" height="1.6098" rx="0.804899" fill="#09B050"/>
                <rect x="9.14615" y="13.0853" width="17.7078" height="1.6098" rx="0.804899" fill="#09B050"/>
              </svg>
            </button>
          </div>
        </div>

        <div v-if="headerOpen" class="flex flex-wrap lg:hidden w-full justify-center text-white py-4 font-degular shadow-[inset_0_4px_6px_rgba(0,0,0,0.1)]">
          <nuxt-link class="flex-none my-auto px-3 font-bold" :to="localePath('quit-subpage-index-pro')">{{ t('navbar.health_pro') }}</nuxt-link>
          <nuxt-link class="flex-none my-auto px-3 font-bold" :to="localePath('quit-subpage-index-hesitate-index')">{{ t('navbar.prepare') }}</nuxt-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    const nuxtApp = useNuxtApp();
    const { t, locale, locales } = useI18n({
      useScope: "local",
    });
    const localePath = useLocalePath();
    const headerOpen = ref(false);

    return { t, locale: locale.value, localePath, nuxtApp, headerOpen };
  },
  computed: {
    enLanguage() {
      return this.locale === "en"
    },
    logo() {
      return this.locale === "fr"
        ? "/images/quit/logo_fr.png"
        : "/images/quit/logo_en.png"
    }
  }
};
</script>

<i18n lang="json">
{
  "fr": {
    "navbar": {
      "i_quit": "J'ARRÊTE.",
      "health_pro": "Professionnels de la santé",
      "prepare": "Je me prépare",
      "connect": "Je me connecte",
      "connect_mobile": "Connexion",
      "register": "Je m'inscris",
      "register_mobile": "Inscription"
    }
  },
  "en": {
    "navbar": {
      "i_quit": "I QUIT NOW.",
      "health_pro": "Health Care Providers",
      "prepare": "Getting Ready",
      "connect": "Log in",
      "connect_mobile": "Log in",
      "register": "Sign in",
      "register_mobile": "Sign in"
    }
  }
}
</i18n>
